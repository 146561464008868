import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AdminCustomSettings, AppStore, UnitPong } from "types";

const initialState: AppStore = {
  settings: null,
  latestPing: undefined,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<AdminCustomSettings>) => {
      state.settings = action.payload;
    },
    setPing: (state, action: PayloadAction<UnitPong | null>) => {
      state.latestPing = action.payload;
    },
    setHalVersion: (state, action: PayloadAction<string>) => {
      state.halVersion = action.payload;
    },
  },
});

export const slice = appSlice.reducer;
export const actions = appSlice.actions;
