import { useState } from "react";
import { useCustomSelector } from "store/useStore";

import MiniBasket from "./Mini";
import BasketContent from "./Content";

import "./Basket.scss";

export default function Basket() {
  const { basketItems } = useCustomSelector((state) => state.basket);

  const [isExpanded, setIsExpanded] = useState(false);

  if (basketItems.length === 0) {
    return <></>;
  }

  return (
    <div className="basket">
      <div className="basket-background-layer" />
      <MiniBasket handleExpandClick={() => setIsExpanded((prev) => !prev)} />
      {isExpanded && <BasketContent />}
    </div>
  );
}
