import { useCallback, useEffect, useState } from "react";
import { sessionStore } from "utils/helpers/storage";

const minutesBetweenRefreshCheck = 1;

// Refreshing the page at midnight + clearing storage
export default function useAutoRefresh() {
  const [lastRefresh] = useState(new Date().getTime());
  const [currentHour, setCurrentHour] = useState(new Date().getHours());

  const handleSessionRefresh = useCallback(() => {
    sessionStore.clear();
    window.location.reload();
  }, []);

  useEffect(() => {
    function getMinutesSinceLastRefresh() {
      let now = new Date().getTime();
      let millisecondsSinceLastRefresh = now - lastRefresh;
      return millisecondsSinceLastRefresh / 1000 / 60;
    }

    const isMidnightHour = currentHour === 0;
    if (isMidnightHour && getMinutesSinceLastRefresh() > minutesBetweenRefreshCheck) {
      handleSessionRefresh();
    }
  }, [currentHour, lastRefresh, handleSessionRefresh]);

  // Updating the hour we're currently in
  useEffect(() => {
    function updateCurrentHour() {
      setCurrentHour(new Date().getHours());
    }

    let interval = setInterval(updateCurrentHour, 60 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
}
