import { useMemo } from "react";

import { PaymentStatus } from "types";

export default function usePaymentState(paymentStatusCode: PaymentStatus | null) {
  const isCriticalError = useMemo(
    () =>
      paymentStatusCode === PaymentStatus.STATE_ERROR ||
      paymentStatusCode === PaymentStatus.CODE_ERROR ||
      paymentStatusCode === PaymentStatus.DEVICE_ERROR ||
      paymentStatusCode === PaymentStatus.DISCONNECTED,
    [paymentStatusCode]
  );
  const isFailed = useMemo(() => paymentStatusCode === PaymentStatus.PAYMENT_REJECTED || paymentStatusCode === PaymentStatus.PAYMENT_FAILED, [paymentStatusCode]);
  const isCancelled = useMemo(() => paymentStatusCode === PaymentStatus.PAYMENT_CANCELLED, [paymentStatusCode]);

  return { isCriticalError, isFailed, isCancelled };
}
