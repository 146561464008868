import logo from "assets/img/logo.png";

import "./Header.scss";

export default function Header({ className = "" }: { className?: string }) {
  return (
    <div className={`header ${className}`}>
      <img src={logo} alt="" />
    </div>
  );
}
