export interface Unit {
  id: string;
  customerId: string;
  name: string;
  lastSeenUtc: string;
  frontendVersion: number;
}

export interface UnitSetting {
  customerId: string;
  unitId: string;
  terminalId: string;
  terminalApiKey: string;
  accountCardEnabled: boolean;
  paymentTerminalEnabled: boolean;
}

export interface UnitSetup {
  unitId: string;
  customerId: string;
  id: number;
  token: string;
  authTokenId: number;
  authToken: {
    id: number;
    customerId: string;
    unitId: string;
    token: string;
    createdUtc: string;
    expirationUtc: string;
  };
  unitSetting: UnitSetting;
}

export interface UnitPing {
  frontendVersion: number;
  halVersion?: string;
}

export enum PongMessageType {
  "UP_TO_DATE" = 0,
  "NEW_VERSION_AVAILABLE" = 1,
}

export interface UnitPong {
  lastSeenUtc: string;
  messages: { message: string; type: PongMessageType }[];
}
