import { useMemo } from "react";
import { useCustomSelector } from "store/useStore";

import { Product } from "types";

const isAfterFromTime = ({ fromTime, currentHour, currentMinute }: { fromTime: Date; currentHour: number; currentMinute: number }) => {
  if (fromTime.getHours() > currentHour) {
    // Later hour than now
    return false;
  }
  if (fromTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return fromTime.getMinutes() <= currentMinute;
  }
  // Hour is before current one
  return true;
};

const isBeforeToTime = ({ toTime, currentHour, currentMinute }: { toTime: Date; currentHour: number; currentMinute: number }) => {
  if (toTime.getHours() <= currentHour) {
    // Before current hour
    return false;
  }
  if (toTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return toTime.getMinutes() > currentMinute;
  }
  // Hour is after current one
  return true;
};

export default function useProducts() {
  const { menus } = useCustomSelector((state) => state.menu);

  const activeMenus = useMemo(() => {
    let available = menus;
    const now = new Date();

    // Weekday filter
    const today = now.getDay();
    available = available.filter(({ activeWeekDays = [] }) => activeWeekDays.includes(today));

    // Time of day filter
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    available = available.filter(({ activeFrom, activeTo }) => {
      if (!activeFrom || !activeTo) {
        return true;
      }
      let fromTimestamp = new Date(activeFrom);
      let toTimestamp = new Date(activeTo);
      return isAfterFromTime({ fromTime: fromTimestamp, currentHour, currentMinute }) && isBeforeToTime({ toTime: toTimestamp, currentHour, currentMinute });
    });

    return available;
  }, [menus]);

  const availableProducts = useMemo(() => {
    return activeMenus.reduce((acc, curr) => acc.concat(curr.products), [] as Product[]);
  }, [activeMenus]);

  return { products: availableProducts, isSingleProductSetup: false }; // availableProducts.length === 1
}
