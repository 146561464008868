export function customInsightsEvent(eventName: string, data: { [key: string]: string }) {
  if (!window.appInsights) {
    return;
  }

  try {
    window.appInsights.trackEvent({ name: eventName, properties: data });
  } catch (err) {
    console.trace(err);
  }
}
