import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomSelector } from "store/useStore";

import Button from "components/ui/Button";

import useBasketValues from "hooks/useBasketValues";

import { ReactComponent as ShoppingBagIcon } from "assets/img/icons/shopping-bag.svg";
import { ReactComponent as ArrowIcon } from "assets/img/icons/arrow-left.svg";

import { UserFlowType } from "types";

import "./Mini.scss";

export default function MiniBasket({ handleExpandClick }: { handleExpandClick: () => void }) {
  const { t } = useTranslation();

  const { userFlowType } = useCustomSelector((state) => state.user);

  const { count, displayTotal } = useBasketValues();

  const navigate = useNavigate();

  function handleContinueClick() {
    navigate(userFlowType === UserFlowType.GUEST ? "/payment" : "/order");
  }

  return (
    <div className="mini-basket">
      <div className="mini-basket-toggle" onClick={handleExpandClick}>
        <div className="shopping-bag">
          <ShoppingBagIcon className="shopping-bag-icon" />
          <div className="count-indicator">
            <span>{count}</span>
          </div>
        </div>
        <p>{t("basket:Show")}</p>
        <ArrowIcon className="arrow-icon" />
      </div>
      <Button className="place-order-button" size="lg" onClick={handleContinueClick}>
        <span>{t("basket:PlaceOrder")}</span>
        {userFlowType === UserFlowType.GUEST && <span>{`${displayTotal} ${t("common:Currency")}`}</span>}
      </Button>
    </div>
  );
}
