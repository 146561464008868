import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/ui/Button";
import Page from "components/ui/Page";

import useBasketValues from "hooks/useBasketValues";

import { ReactComponent as ExitIcon } from "assets/img/icons/exit.svg";

import card from "assets/img/card.jpg";

export default function PaymentInProgressState({ isLoading, handleCancelClick }: { isLoading: boolean; handleCancelClick: () => void }) {
  const { t } = useTranslation();

  // Cancel button is initially disabled, and enabled on delay
  const [cancelIsDisabled, setCancelIsDisabled] = useState(true);

  const { displayTotal } = useBasketValues();

  useEffect(() => {
    function handleTimeout() {
      setCancelIsDisabled(false);
    }
    let timeout = setTimeout(handleTimeout, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Page className="payment-page">
      <div className="payment-content">
        <Button theme="danger" className="cancel-button" attributes={{ disabled: isLoading || cancelIsDisabled }} onClick={handleCancelClick}>
          <ExitIcon />
          {t("common:Cancel")}
        </Button>
        <h1>{t("payment:Title")}</h1>
        <img alt="credit-card" src={card} />
        <div className="description">
          <p>{t("payment:AmountForPayment")}</p>
          <p>{`${displayTotal} ${t("common:Currency")}`}</p>
        </div>
      </div>
    </Page>
  );
}
