import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StorageName } from "utils/constants/storage";
import { sessionStore } from "utils/helpers/storage";

import { UserStore, UserFlowType } from "types";

const initialState: UserStore = {
  cardNumber: null,
  userFlowType: sessionStore.get(StorageName.USER_FLOW_TYPE) || null,
  externalPaymentId: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCardNumber: (state, action: PayloadAction<number | null>) => {
      state.cardNumber = action.payload;
    },
    setExternalPaymentId: (state, action: PayloadAction<string | null>) => {
      state.externalPaymentId = action.payload;
    },
    setUserType: (state, action: PayloadAction<UserFlowType | null>) => {
      if (action.payload) {
        sessionStore.save(StorageName.USER_FLOW_TYPE, action.payload);
      } else {
        sessionStore.remove(StorageName.USER_FLOW_TYPE);
      }
      state.userFlowType = action.payload;
    },
  },
});

export const slice = userSlice.reducer;
export const actions = userSlice.actions;
