import { Route, Routes, Navigate } from "react-router";

import Home from "components/home";
import Menu from "components/menu";
import Payment from "components/payment";
import Order from "components/order";
import Credentials from "components/credentials";

import useAutoRefresh from "hooks/useAutoRefresh";
import usePing from "hooks/usePing";

export default function App() {
  useAutoRefresh();
  usePing();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/order" element={<Order />} />
      <Route path="/credentials" element={<Credentials />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
