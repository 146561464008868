import { BasketItem, Product } from "types";
import { sessionStore } from "./storage";
import { StorageName } from "utils/constants/storage";

type StorageBasketItem = { id: string; amount: number };

export function storeBasketItems(basketItems: BasketItem[]) {
  let storageBasketItems: StorageBasketItem[] = basketItems.map((item) => ({ id: item.productId, amount: item.amount }));
  sessionStore.save(StorageName.BASKET_ITEMS, storageBasketItems);
}

export function getBasketItemsFromStorage(products: Product[]): BasketItem[] {
  if (products.length === 0) {
    return [];
  }

  let storageBasketItems = (sessionStore.get(StorageName.BASKET_ITEMS) || []) as StorageBasketItem[];
  let basketItems: BasketItem[] = [];
  storageBasketItems.forEach((storageItem) => {
    let product = products.find(({ productId }) => productId === storageItem.id);
    if (product) {
      basketItems.push({ ...product, amount: storageItem.amount });
    }
  });
  return basketItems;
}
