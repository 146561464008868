import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "assets/img/icons/arrow-left.svg";

import "./Footer.scss";

export default function Footer() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  function handleClick() {
    navigate("/");
  }

  return (
    <div className="footer" onClick={handleClick}>
      <ArrowIcon />
      <p>{t("basket:FooterAction")}</p>
    </div>
  );
}
