import { useTranslation } from "react-i18next";

import "./Offline.scss";

export default function OfflineAlert() {
  const { t } = useTranslation();

  return (
    <div className="offline-alert">
      <div className="alert-animation-box" />
      <div className="alert-content">
        <p>{t("common:Offline.NoInternet")}</p>
        <p>{t("common:Offline.Reconnecting")}</p>
      </div>
    </div>
  );
}
