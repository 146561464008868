import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sessionStore } from "utils/helpers/storage";
import { StorageName } from "utils/constants/storage";

import { Menu, MenuStore } from "types";

const initialState: MenuStore = {
  isLoading: false,
  menus: sessionStore.get(StorageName.MENUS) || [],
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      sessionStore.save(StorageName.MENUS, action.payload);
      state.menus = action.payload;
    },
  },
});

export const slice = menuSlice.reducer;
export const actions = menuSlice.actions;
