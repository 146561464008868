import { Component } from "react";

import Button from "components/ui/Button";

import { customInsightsEvent } from "utils/helpers/logs";

import "./ErrorBoundary.scss";

export default class ErrorBoundary extends Component<{ children: any }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.trace(error);
    console.log(error);
    console.log(errorInfo);

    if (error?.message && process.env.NODE_ENV === "production") {
      customInsightsEvent("ErrorBoundary", { message: error.message });
    }
  }

  handleButtonClick() {
    window.location.replace(window.location.origin);
  }

  render() {
    if (this.state.hasError) {
      let appInsightsTraceId = window.appInsights?.context?.telemetryTrace?.traceID;

      return (
        <div className="error-boundary">
          <h1 className="error-boundary-title">Beklager, der er sket en fejl</h1>
          {!!appInsightsTraceId && <p className="insights-trace-text">{appInsightsTraceId}</p>}
          <Button onClick={this.handleButtonClick}>Tilbage til forsiden</Button>
        </div>
      );
    }

    return this.props.children;
  }
}
