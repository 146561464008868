import { useEffect, useState } from "react";

import HALService from "services/hal";

export default function useRfidReader(enabled: boolean) {
  const [enabledOnMount] = useState(enabled);
  const [uid, setUid] = useState<number | null>(null);

  useEffect(() => {
    async function readRfid() {
      let activeRfid = await HALService.getActiveRfid();
      if (activeRfid.isSuccess() && !!activeRfid.data) {
        setUid(activeRfid.data.uidUInt);
      }
    }

    let interval: any;
    if (enabledOnMount) {
      interval = setInterval(readRfid, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [enabledOnMount]);

  return { uid };
}
