import { useTranslation } from "react-i18next";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { basketActions } from "store/basket";

import useBasketValues from "hooks/useBasketValues";

import { BasketItem, UserFlowType } from "types";

import { ReactComponent as ExitIcon } from "assets/img/icons/exit.svg";

import "./Content.scss";

export default function BasketContent() {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const { userFlowType } = useCustomSelector((state) => state.user);
  const { basketItems, count } = useBasketValues();

  function handleDeleteProductClick(item: BasketItem) {
    dispatch(basketActions.removeBasketItem(item));
  }

  return (
    <div className="basket-content">
      <div className="content-header">
        <h2 className="content-title">{t("basket:ExpandedCartTitle")}</h2>
        <div className="product-count">
          <p>{count}</p>
        </div>
      </div>
      <div className="product-list">
        {basketItems.map((item) => {
          return (
            <div className={`basket-product ${userFlowType === UserFlowType.GUEST ? "" : "without-price"}`} key={item.productId}>
              <div className="product-name-wrapper">
                <p className="product-name">
                  {item.amount > 1 && <span>{`${item.amount} x `}</span>}
                  {item.name}
                </p>
                <p className="product-price">{item.price * item.amount},-</p>
              </div>
              <div className="product-delete-icon" onClick={() => handleDeleteProductClick(item)}>
                <ExitIcon />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
