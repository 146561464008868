import common from "./common.json";
import basket from "./basket.json";
import home from "./home.json";
import payment from "./payment.json";
import order from "./order.json";
import token from "./token.json";

export const da = {
  common,
  basket,
  home,
  payment,
  order,
  token,
};
