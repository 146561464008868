import HttpErrorResponse from "./models/HttpErrorResponse";
import HttpSuccessResponse from "./models/HttpSuccessResponse";

type HttpResponse<T> = HttpSuccessResponse<T> | HttpErrorResponse;

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

async function request<T>(method: HttpMethod, path: string, args?: { token?: string; payload?: Object | string }): Promise<HttpResponse<T>> {
  const { token, payload } = args || {};
  const headers = new Headers();
  let body;

  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  if (payload) {
    headers.append("Content-Type", "application/json");
    body = JSON.stringify(payload);
  }

  try {
    const response = await fetch(path, { method, headers, body });
    let responseBody;

    if (response.body) {
      const contentType = response.headers.get("Content-Type");
      const isJsonResponse = contentType && contentType.includes("application/json");

      if (isJsonResponse) {
        responseBody = await response.json();
      } else {
        responseBody = await response.text();
      }
    }

    if (response.ok) {
      return new HttpSuccessResponse<T>(responseBody);
    } else {
      return new HttpErrorResponse(response.status, responseBody);
    }
  } catch (e: any) {
    return new HttpErrorResponse(0, e?.message || `Request to '${path}' failed`);
  }
}

export { request };
