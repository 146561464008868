import { request } from "http/client";

import { AdminAppSettings, AdminCustomSettings, PaymentProcessingResponse } from "types";

const api = process.env.REACT_APP_HAL_URI || `http://localhost:8888`;

const HALService = {
  getVersion: async () => {
    return request<string>("GET", `${api}/Admin/Version`);
  },
  getCustomSettings: async () => {
    return request<string>("GET", `${api}/Admin/CustomSettings`);
  },
  updateCustomSettings: async (settings: AdminCustomSettings) => {
    return request<void>("POST", `${api}/Admin/CustomSettings`, { payload: settings });
  },
  getAppSettings: async () => {
    return request<string>("GET", `${api}/Admin/AppSettings`);
  },
  updateAppSettings: async (settings: AdminAppSettings) => {
    return request<void>("POST", `${api}/Admin/AppSettings`, { payload: settings });
  },
  restartService: async () => {
    return request<void>("POST", `${api}/Admin/ShutDown`);
  },
  getActiveRfid: async () => {
    return request<{ uidString: string; uidUInt: number; uid: string; time: string } | void>("GET", `${api}/Rfid/Read`);
  },
  startPayment: async (paymentId: string, amount: number) => {
    return request<PaymentProcessingResponse>("POST", `${api}/Payment/ProcessTransaction`, {
      payload: { id: paymentId, amountCents: amount * 100 },
    });
  },
  cancelPayment: async (paymentId: string) => {
    return request<void>("POST", `${api}/Payment/CancelTransaction/${paymentId}`);
  },
  print: async (payload: string) => {
    return request<void>("POST", `${api}/Print/PrintHTML`, { payload });
  },
};

export default HALService;
