import OutdatedVersionAlert from "./OutdatedVersion";
import OfflineAlert from "./Offline";
import CountdownModal from "./CountdownModal";

import useAlertUpdates from "./useAlertUpdates";
import useCountdown from "./useCountdown";

export default function Alert({ withCountdown = false }: { withCountdown?: boolean }) {
  const { newVersionAvailable, isOffline } = useAlertUpdates();
  const { showCountdownModal, countdown, dismissCountdown } = useCountdown();

  if (isOffline) {
    return <OfflineAlert />;
  }

  if (withCountdown && showCountdownModal) {
    return <CountdownModal countdown={countdown} handleDismiss={dismissCountdown} />;
  }

  if (newVersionAvailable) {
    return <OutdatedVersionAlert />;
  }

  return <></>;
}
