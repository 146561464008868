import { ReactNode } from "react";

import "./Button.scss";

type Theme = "primary" | "secondary" | "danger";
type Size = "md" | "lg";

interface Props {
  children: string | ReactNode | ReactNode[];
  className?: string;
  attributes?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  theme?: Theme;
  size?: Size;
  onClick?: React.MouseEventHandler;
}

export default function Button({ children, className = "", attributes = {}, theme = "primary", size = "md", onClick }: Props) {
  return (
    <button className={`button theme-${theme} size-${size} ${className}`.trim()} {...attributes} onClick={onClick}>
      {children}
    </button>
  );
}
