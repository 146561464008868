import { store } from "store/store";

let environment = "";

if (window.location.href.includes("ci.")) {
  environment = "ci.";
} else if (window.location.href.includes("staging.")) {
  environment = "staging.";
}

const getToken = () => {
  return store.getState().app.settings?.unitToken || "";
};

const getEnvironment = () => {
  return environment;
};

export { getToken, getEnvironment };
