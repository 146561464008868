import { ReactNode } from "react";

import "./Card.scss";

export default function Card({ children, className = "", onClick }: { children: ReactNode | ReactNode[]; className?: string; onClick?: React.MouseEventHandler }) {
  return (
    <div className={`card ${className}`} onClick={onClick}>
      <div className="card-background" />
      <div className="card-content">{children}</div>
    </div>
  );
}
