import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { da } from "./languages/da-DK";

const resources = {
  "da-DK": da,
};

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources,

  lng: "da-DK",
  fallbackLng: "da-DK",

  ns: ["common", "basket", "home", "payment", "order", "token"],
  defaultNS: "common",

  supportedLngs: ["da-DK"],
  load: "currentOnly",

  react: {
    useSuspense: true,
  },

  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
