import "./Spinner.scss";

export default function Spinner() {
  return (
    <div className="spinner-wrapper">
      <div className="spinner-background"></div>
      <div className="spinner-content">
        <div className="spinner"></div>
      </div>
    </div>
  );
}
