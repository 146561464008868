import { useMemo } from "react";
import { useCustomSelector } from "store/useStore";
import { formatPrice } from "utils/helpers/view";

export default function useBasketValues() {
  const { basketItems } = useCustomSelector((state) => state.basket);

  const basketItemCount = useMemo(() => basketItems.reduce((accumulated, current) => accumulated + current.amount, 0), [basketItems]);
  const basketTotal = useMemo(() => basketItems.reduce((accumulated, current) => accumulated + current.price * current.amount, 0), [basketItems]);

  return { basketItems, count: basketItemCount, total: basketTotal, displayTotal: formatPrice(basketTotal) };
}
