import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "components/ui/Button";

import "./CountdownModal.scss";

export default function CountdownModal({ countdown, handleDismiss }: { countdown: number | null; handleDismiss: () => void }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="modal-wrapper">
      <div className="modal-background" />
      <div className="modal">
        <h2>{t("common:Countdown.Title")}</h2>
        <p>{t("common:Countdown.Text", { countdown: countdown || 0 })}</p>
        <div className="countdown-actions">
          <Button onClick={handleDismiss}>{t("common:Countdown.Continue")}</Button>
          <Button theme="secondary" onClick={() => navigate("/")}>
            {t("common:Countdown.Cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
