import { BasketItem, UserFlowType } from "types";

export function getJsonSettings(settings: string) {
  try {
    return JSON.parse(settings);
  } catch (error) {
    return null;
  }
}

export function getPaymentReceiptHtml({
  paymentId,
  terminalId,
  amount,
  status,
  cardIssuer,
  cardNumber,
  externalId,
}: {
  paymentId: string;
  terminalId: string;
  amount: number;
  status: "GODKENDT" | "FEJLET";
  cardIssuer?: string;
  cardNumber?: string;
  externalId?: string;
}) {
  return `Terminal: ${terminalId}<br>
${new Date().toLocaleString()}<br><br>
${!!cardIssuer ? `${cardIssuer}<br>` : ""}
${!!cardNumber ? `${cardNumber}<br>` : ""}
${!!externalId ? `Ref.: ${externalId}<br>` : ""}
ID: ${paymentId}<br>
KØB<br>
DKK                ${amount.toLocaleString("da", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br>
${status}<br>`;
}

export function getOrderReceiptHtml({
  userFlowType,
  externalPaymentId,
  products,
  displayTotal,
}: {
  userFlowType: UserFlowType | null;
  externalPaymentId?: string | null;
  products: BasketItem[];
  displayTotal: string;
}) {
  const productElements = products.map((product) => {
    return `${product.amount} x ${product.name}`;
  });
  let guestFlowSection = "";
  if (userFlowType === UserFlowType.GUEST) {
    guestFlowSection = `<br>----------<br><b>Ordreværdi: ${displayTotal} kr</b><br/>${!!externalPaymentId ? `Betalingsreference: ${externalPaymentId}` : ""}`;
  }

  return `<h1>Din bestilling</h1><br>${productElements.join("<br>")}${guestFlowSection}`;
}
