import { useTranslation } from "react-i18next";

import Button from "components/ui/Button";

import "./OutdatedVersion.scss";

export default function OutdatedVersionAlert() {
  const { t } = useTranslation();

  function handleClick() {
    window.location.reload();
  }

  return (
    <div className="outdated-version-alert">
      <p>{t("common:OutdatedVersion.Text")}</p>
      <Button onClick={handleClick}>{t("common:OutdatedVersion.Action")}</Button>
    </div>
  );
}
