import "./Background.scss";

export default function HomeBackground() {
  return (
    <>
      <div className="home-background-image" />
      <div className="home-background-overlay" />
    </>
  );
}
