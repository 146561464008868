import { MouseEvent } from "react";

import "./Counter.scss";

export default function Counter({ count, handleIncrease, handleDecrease }: { count: number; handleIncrease: (updatedCount: number) => void; handleDecrease: (updatedCount: number) => void }) {
  function onIncreaseClick(event: MouseEvent) {
    event.stopPropagation();
    handleIncrease(count + 1);
  }

  function onDecreaseClick(event: MouseEvent) {
    event.stopPropagation();
    handleDecrease(count - 1);
  }

  return (
    <div className="counter">
      <button onClick={onDecreaseClick}>-</button>
      <p>{count}</p>
      <button onClick={onIncreaseClick}>+</button>
    </div>
  );
}
