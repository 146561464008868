import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const allowedIdleTimeInSeconds = 30;
const countdownInSeconds = 15;

export default function useCountdown() {
  const [countdown, setCountdown] = useState<number | null>(null);

  const [lastNavigatedTime, setLastNavigatedTime] = useState(new Date().getTime());
  const [showCountdownModal, setShowCountdownModal] = useState(false);

  const navigate = useNavigate();

  const handleUserInteraction = useCallback(() => {
    setLastNavigatedTime(new Date().getTime());
    setShowCountdownModal(false);
    setCountdown(null);
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleUserInteraction);
    document.addEventListener("keypress", handleUserInteraction);

    return () => {
      document.removeEventListener("mousemove", handleUserInteraction);
      document.removeEventListener("keypress", handleUserInteraction);
    };
  }, [handleUserInteraction]);

  // Setting last navigated time
  useEffect(() => {
    function handleTimeout() {
      let limitInMilliseconds = allowedIdleTimeInSeconds * 1000;
      if (new Date().getTime() - lastNavigatedTime > limitInMilliseconds) {
        setShowCountdownModal(true);
      }
    }
    let interval = setInterval(handleTimeout, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [lastNavigatedTime]);

  useEffect(() => {
    function handleCountdownInterval() {
      setCountdown((prev) => {
        if (prev === null) {
          return countdownInSeconds;
        }
        if (prev === 0) {
          return 0;
        }
        return prev - 1;
      });
    }

    let interval: any;
    if (showCountdownModal) {
      setCountdown(countdownInSeconds);
      interval = setInterval(handleCountdownInterval, 1000);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [showCountdownModal]);

  useEffect(() => {
    if (countdown === 0) {
      setShowCountdownModal(false);
      navigate("/");
    }
  }, [countdown, navigate]);

  return { showCountdownModal, countdown, dismissCountdown: () => setShowCountdownModal(false) };
}
